import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import { InputField } from '../../Components/InputField/InputField';
import { useCaseManaged } from '../../Models/useCaseManaged.model';
import { deleteAgent, getAllAgent } from '../../Services/http/useCase.services';
import { getComparator, Order, stableSort } from '../../Utils/tableHelpers';
import Config from '../../config.json';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paperManagement: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    headerManagement: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '28px',
        justifyContent: 'space-between',
        '& ...MuiInputBase-root': {
            height: 28,
        },
    },
    buttonManagement: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
    },
    statusBox: {
        borderRadius: theme.spacing(2),
        textAlign: 'center',
        padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
    },
    activated: {
        backgroundColor: theme.palette.success.main,
    },
    inactivated: {
        backgroundColor: theme.palette.error.main,
    },
}));

const pageTitle: string = 'Use Case Management';
const chatUrl: string = Config.VSF_K8S_CHAT_API_URL;

type StatusBoxProps = {
    status: boolean;
};

const StatusBox: React.FC<StatusBoxProps> = ({ status }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.statusBox} ${status ? classes.activated : classes.inactivated}`}>
            <Typography variant="body2">{status ? 'Active' : 'Inactive'}</Typography>
        </div>
    );
};

export const UseCaseManagementPage: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [allUseCases, setAllUseCases] = useState<Array<useCaseManaged>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [useCasesSelected, setUseCasesSelected] = useState<Array<useCaseManaged>>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [deletePopupOpenIsOpen, setDeletePopupOpenIsOpen] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof useCaseManaged>('domain');

    const buttonStyle = {
        backgroundColor: theme.palette.text.secondary,
        color: theme.palette.background.default,
    };

    const linkStyle = {
        color: theme.palette.background.default,
        textDecoration: 'none',
    };

    const dateOptions: any = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    useEffect(() => {
        getAllUsesCase();
    }, []);

    async function getAllUsesCase() {
        setLoading(false);
        await getAllAgent()
            .then((useCasesList: { data: { agents: Array<useCaseManaged> } }) => {
                setAllUseCases(useCasesList.data.agents);
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    console.error(e.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleChangeUseCaseSelection(useCase: useCaseManaged) {
        const useCaseSelectedTemp: Array<useCaseManaged> = [...useCasesSelected];
        if (useCaseSelectedTemp.includes(useCase)) {
            useCaseSelectedTemp.splice(useCaseSelectedTemp.indexOf(useCase), 1);
        } else {
            useCaseSelectedTemp.push(useCase);
        }
        setUseCasesSelected(useCaseSelectedTemp);
    }

    function handleUseCaseDeletion() {
        setDeletePopupOpenIsOpen(true);
    }

    function deleteUseCases() {
        useCasesSelected.forEach(async (useCase: useCaseManaged) => {
            await deleteAgent(useCase.id)
                .then(() => {
                    getAllUsesCase();
                    enqueueSnackbar(`Delete Use case ${useCase.name} was successful`, {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e) => {
                    if (e.response?.data?.message) {
                        console.error(e.response.data.message);
                    }
                    enqueueSnackbar(`Delete Use case ${useCase.name} wasn't successful`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .finally(() => {
                    setDeletePopupOpenIsOpen(false);
                    setUseCasesSelected([]);
                });
        });
        setUseCasesSelected([]);
    }

    function deactivateUseCases() {}

    function duplicateUseCase() {}

    function displayDate(dateLongString: string): string {
        const date = new Date(dateLongString);
        return date.toLocaleDateString(navigator.language, dateOptions);
    }

    function filteredUseCasesList(): Array<useCaseManaged> {
        return allUseCases.filter((useCase) => {
            return useCase.name.toLowerCase().includes(searchString.toLowerCase());
        });
    }

    const handleRequestSort = (property: keyof useCaseManaged) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Grid display="flex" direction="column" gap={2} className={classes.container} container>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" id="usecase-management-breadcrumb-home">
                    Home
                </Link>
                <Link
                    color="textPrimary"
                    href="/use-cases-management"
                    aria-current="page"
                    id="usecase-management-breadcrumb-usecase-management"
                >
                    {pageTitle}
                </Link>
            </Breadcrumbs>
            <Paper className={classes.paperManagement}>
                <Grid display="flex" direction="row" gap={2} alignItems="center" container>
                    <Typography variant="h6">{pageTitle}</Typography>
                    <Tooltip title="Regroup all Use Case information" placement="right">
                        <IconButton>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <div className={classes.headerManagement}>
                    <InputField
                        placeHolder="Search..."
                        value={searchString}
                        onChange={(v) => setSearchString(v)}
                        autoComplete="name"
                        color="secondary"
                        size="small"
                    />
                    <div className={classes.buttonManagement}>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={useCasesSelected.length === 0}
                            onClick={handleUseCaseDeletion}
                            id="usecase-management-delete"
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            disabled={true}
                            onClick={deactivateUseCases}
                            id="usecase-management-deactivate"
                        >
                            Deactivate
                        </Button>
                        <Button
                            variant="contained"
                            disabled={true}
                            onClick={duplicateUseCase}
                            id="usecase-management-duplicate"
                        >
                            Duplicate
                        </Button>
                        <Button
                            variant="contained"
                            sx={buttonStyle}
                            startIcon={<AddIcon />}
                            id="usecase-management-create-new"
                        >
                            <Link href="/create-use-case" style={linkStyle}>
                                <Typography variant="body1">Create new UseCase</Typography>
                            </Link>
                        </Button>
                    </div>
                </div>
                {loading ? (
                    <div>
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleRequestSort('name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'domain'}
                                            direction={orderBy === 'domain' ? order : 'asc'}
                                            onClick={() => handleRequestSort('domain')}
                                        >
                                            Domain
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'creator'}
                                            direction={orderBy === 'creator' ? order : 'asc'}
                                            onClick={() => handleRequestSort('creator')}
                                        >
                                            Creator
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">Creation Date</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Running Cost</TableCell>
                                    <TableCell align="center">API Link</TableCell>
                                    <TableCell align="center">Live Version</TableCell>
                                    <TableCell align="center">Edit</TableCell>
                                    <TableCell align="center">Visit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(
                                    filteredUseCasesList(),
                                    getComparator(order, orderBy),
                                ).map((useCase: useCaseManaged) => (
                                    <TableRow key={useCase.id}>
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                id="usecase-table-row-checkbox"
                                                onChange={() =>
                                                    handleChangeUseCaseSelection(useCase)
                                                }
                                                inputProps={{
                                                    'aria-labelledby': useCase.id.toString(),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{useCase.name}</TableCell>
                                        <TableCell align="center">{useCase.domain}</TableCell>
                                        <TableCell align="center">{useCase.creator}</TableCell>
                                        <TableCell align="center">
                                            {displayDate(useCase.created)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <StatusBox status={useCase.is_active} />
                                        </TableCell>
                                        <TableCell align="center">{useCase.budget}</TableCell>
                                        <TableCell align="center">
                                            <Link color="inherit" href={useCase.api_endpoint}>
                                                {useCase.api_endpoint}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{useCase.version}</TableCell>
                                        <TableCell align="center">
                                            <Link
                                                href={`/create-use-case/${useCase.id}`}
                                                id="usecase-table-row-edit"
                                            >
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link
                                                href={`${chatUrl}/chat/${useCase.id}`}
                                                id="usecase-table-row-visit"
                                            >
                                                <IconButton>
                                                    <RemoveRedEyeOutlinedIcon />
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
            <ActionConfirmDialog
                open={deletePopupOpenIsOpen}
                onClose={() => setDeletePopupOpenIsOpen(false)}
                onConfirm={deleteUseCases}
                title={'Delete Use case'}
                content={`Use cases will be delete. Do you want to continue?`}
            />
        </Grid>
    );
};
